const polyfillFromEntries = async () => {
	const fromEntries = await import("fromentries")

	Object.fromEntries = fromEntries.default
}

export const onClientEntry = async () => {
	const promises = []

	if (!Object.fromEntries) {
		promises.push(await polyfillFromEntries())
	}

	if (!("scrollBehavior" in document.documentElement.style)) {
		promises.push(
			(async () => {
				const smooth = await import("smoothscroll-polyfill")
				smooth.polyfill()
			})()
		)
	}

	await Promise.all(promises)
}
